@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

/* base styles */
* {
  margin: 0;
  font-family: Verdana, sans-serif;
  color: #333;
}
h1,
h2 {
  font-family: Georgia, serif;
}

.navbar {
  padding: 1.25rem;
  max-width: 50rem;
  margin: 0 auto;
  border-bottom: 0.1rem solid #f2f2f2;
}
.navbar h1 {
  color: #f1356d;
}
.navbar .links {
  margin-left: auto;
}
.navbar a {
  margin-left: 2rem;
  text-decoration: none;
  padding: 0.25rem;
}
.navbar a:hover {
  color: #f1356d;
}
