@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
/* base styles */
* {
  margin: 0;
  font-family: Verdana, sans-serif;
  color: #333;
}
h1,
h2 {
  font-family: Georgia, serif;
}

.navbar {
  padding: 1.25rem;
  max-width: 50rem;
  margin: 0 auto;
  border-bottom: 0.1rem solid #f2f2f2;
}
.navbar h1 {
  color: #f1356d;
}
.navbar .links {
  margin-left: auto;
}
.navbar a {
  margin-left: 2rem;
  text-decoration: none;
  padding: 0.25rem;
}
.navbar a:hover {
  color: #f1356d;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.itservice {
  border: 0.1em solid black;
  text-align: left;
  padding: 1em;
  margin-bottom: 1em;
}
.itservice h2 {
  margin-bottom: 0.5em;
}

.itservice p {
  margin-bottom: 0.5em;
  line-height: 1.8em;
}

.responsive {
  width: 100%;
  max-width: 40rem;
  height: auto;
}


